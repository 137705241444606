<div class="flex flex-col md:flex-row md:justify-between">
  <div>
    <h1 class="text-3xl font-medium" i18n>
      Order: {{ (job$ | async)?.id || 'Loading...' }}
    </h1>
    <p>{{ (job$ | async)?.clientReference || 'Loading...' }}</p>
  </div>
  <!-- <app-button
    i18n-text
    text="Delete job"
    (click)="showDeleteJobModal = true"
    classes="w-full md:w-auto px-3 flex justify-center md:justify-start mt-2 md:mt-0"
  /> -->
</div>

<div class="mb-4 mt-3">
  <app-breadcrumbs
    [segments]="[
    {title: 'Jobs & tests overview ', url: 'jobs-and-tests'},
    {title: (job$ | async)?.id || 'Loading...'}
    ]"
  />
</div>

<app-segments
  [activeValue]="view"
  (clicked)="view = $event"
  [items]="[
  {
    icon: 'details',
    value: 'details',
    title: 'Job details'
  },
  {
    icon: 'result',
    value: 'samples',
    title: 'Samples & Results'
  },
]"
/>

<div *ngIf="job; else showLoadingSpinner">
  <div
    *ngIf="view === 'details'; else samplesView"
    class="mt-12 flex w-full flex-col lg:flex-row lg:justify-evenly"
  >
    <div
      class="flex w-full flex-row lg:mb-0"
      [ngClass]="{
      'lg:basis-2/5': job.dropOffLocation?.address || job.dropOffImage
    }"
    >
      <div class="w-full max-w-96">
        <div>
          <h2 i18n class="text-sm font-light text-gray-600">Order number</h2>

          <p class="mb-5 mt-1 text-xl text-black">{{ job.id }}</p>
        </div>

        <div class="relative max-w-sm">
          <h2
            i18n
            class="text-sm font-light"
            [ngClass]="{
          'text-gray-600': !editing.customerReference,
          'text-alpha-red': editing.customerReference && form.controls['customerReference'].touched && form.controls['customerReference'].errors,
        }"
          >
            Job reference
          </h2>

          <p
            *ngIf="!editing.customerReference"
            class="mb-5 mt-1 flex items-center text-xl text-black"
          >
            {{ job.clientReference }}

            <ng-container
              *ngIf="isJobPending$ && job.clientReference !== form.value.customerReference"
            >
              <div class="ml-4">
                <app-loading size="h-4 w-4" />
              </div>
            </ng-container>
          </p>

          <app-input
            *ngIf="editing.customerReference"
            [form]="form"
            controlName="customerReference"
          />

          <app-button
            *ngIf="!editing.customerReference"
            [disabled]="isJobPending$ && job.clientReference !== form.value.customerReference"
            icon="edit"
            iconFill="blue"
            iconHeight="18px"
            classes="p-3 absolute top-1 right-0"
            (click)="editMode('customerReference')"
          />

          <div *ngIf="editing.customerReference" class="mb-4 mt-2">
            <app-button
              i18n-text
              text="Cancel"
              classes="py-2 px-6"
              (click)="cancelEdit()"
            />
            <app-button
              i18n-text
              [disabled]="!!form.controls['customerReference'].errors"
              text="Save"
              type="submit"
              classes="py-2 px-6  ml-3"
              (click)="updateCustomerReference()"
            />
          </div>
        </div>

        <div class="relative max-w-sm">
          <ng-container *ngIf="!editing.siteAddress">
            <h2
              i18n
              class="text-sm font-light"
              [ngClass]="{
          'text-gray-600': !editing.siteAddress,
          'text-alpha-red': editing.siteAddress && form.controls['siteAddress'].touched && form.controls['siteAddress'].errors,
        }"
            >
              Address
            </h2>

            <p
              *ngIf="!editing.siteAddress"
              class="mb-5 mt-1 flex items-center pr-6 text-xl text-black"
            >
              <span class="w-full truncate pr-6">{{ job.site }}</span>
              <ng-container
                *ngIf="isJobPending$ && job.site !== form.value.siteAddress"
              >
                <div class="ml-4">
                  <app-loading size="h-4 w-4" />
                </div>
              </ng-container>
            </p>
          </ng-container>

          <app-site-address
            [hidden]="!editing.siteAddress"
            (selected)="selectSiteAddress($event)"
            [clearOnSelect]="false"
          />

          <app-button
            *ngIf="!editing.siteAddress"
            [disabled]="isJobPending$ && job.site !== form.value.siteAddress"
            icon="edit"
            iconFill="blue"
            iconHeight="18px"
            classes="p-3 absolute top-1 right-0"
            (click)="editMode('siteAddress')"
          />

          <div *ngIf="editing.siteAddress" class="mb-4 mt-2">
            <app-button
              i18n-text
              text="Cancel"
              classes="py-2 px-6"
              (click)="cancelEdit()"
            />
            <app-button
              i18n-text
              [disabled]="!!form.controls['siteAddress'].errors"
              text="Save"
              type="submit"
              classes="py-2 px-6  ml-3"
              (click)="updateSiteAddress()"
            />
          </div>

          <div>
            <h2 i18n class="text-sm font-light text-gray-600">
              Samples required
            </h2>

            <p class="mb-5 mt-1 text-xl text-black">
              {{ job.metrics.noOfSamples }}
            </p>
          </div>

          <div *ngIf="userRole !== 'USER' && job.createdBySummary">
            <h2 i18n class="text-sm font-light text-gray-600">Created by</h2>

            <p class="mb-5 mt-1 text-xl text-black">
              {{ job.createdBySummary.firstName }}
              {{job.createdBySummary.lastName}}
            </p>
          </div>

          <div class="relative max-w-sm" [formGroup]="form">
            <h2
              i18n
              class="text-sm font-light"
              [ngClass]="{
          'text-gray-600': !editing.userId,
          'text-alpha-red': editing.userId && form.controls['userId'].touched && form.controls['userId'].errors,
        }"
            >
              Assigned to
            </h2>

            <p
              *ngIf="!editing.userId"
              class="mb-5 mt-1 flex items-center text-xl text-black"
            >
              {{ job.userId | organisationUser | async }}

              <ng-container
                *ngIf="isJobPending$ && job.userId !== form.value.userId"
              >
                <div class="ml-4">
                  <app-loading size="h-4 w-4" />
                </div>
              </ng-container>
            </p>

            <ng-select
              *ngIf="editing.userId"
              [items]="organisationUsers"
              formControlName="userId"
              bindLabel="name"
              bindValue="id"
              [clearable]="false"
              [multiple]="false"
              i18n-placeholder
              placeholder="Please select"
            >
            </ng-select>

            <app-button
              *ngIf="userRole !== 'USER' && !editing.userId"
              [disabled]="isJobPending$ && job.userId !== form.value.userId"
              icon="edit"
              iconFill="blue"
              iconHeight="18px"
              classes="p-3 absolute top-1 right-0"
              (click)="editMode('userId')"
            />

            <div *ngIf="editing.userId" class="mb-4 mt-2">
              <app-button
                i18n-text
                text="Cancel"
                classes="py-2 px-6"
                (click)="cancelEdit()"
              />
              <app-button
                i18n-text
                [disabled]="!!form.controls['userId'].errors"
                text="Save"
                type="submit"
                classes="py-2 px-6  ml-3"
                (click)="updateUserId()"
              />
            </div>
          </div>

          <div>
            <h2 i18n class="text-sm font-light text-gray-600">Status</h2>

            <p class="mb-5 mt-1 text-xl text-black">{{ job.status }}</p>
          </div>
        </div>
      </div>
    </div>

    <ng-container *ngIf="job.dropOffLocation?.address || job.dropOffImage">
      <app-divider class="mx-10 hidden lg:block lg:basis-1/5" />

      <div class="flex flex-col justify-center lg:basis-2/5 lg:justify-start">
        <!-- Drop off location -->
        <div *ngIf="job.dropOffImage" class="mb-5">
          <h2 i18n class="text-sm font-light text-gray-600">Drop off image</h2>

          <img
            [src]="job.dropOffImage"
            i18n-alt
            alt="Job sample image"
            class="mt-1 max-h-96 rounded"
          />
        </div>

        <div *ngIf="job.dropOffLocation?.address">
          <h2 i18n class="text-sm font-light text-gray-600">
            Drop off location
          </h2>

          <p class="mb-5 mt-1 text-xl text-black">
            {{ job.dropOffLocation?.address }}
          </p>
        </div>
      </div>
    </ng-container>
  </div>

  <ng-template #samplesView>
    <div
      *ngIf="!showCreateSampleModal"
      class="mt-10 grid grid-cols-1 gap-4 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 3xl:grid-cols-5"
    >
      <ng-container
        *ngIf="(isSamplesPending$ | async) === false; else samplesLoadingSpinner"
      >
        <div
          *ngFor="let sample of samples$ | async"
          class="relative rounded-xl p-6 shadow"
        >
          <div class="absolute right-4 top-4">
            <app-button
              icon="ellipsis"
              iconFill="#1990FF"
              classes="h-10 w-10"
              (click)="sampleMenuOpen = sample.id"
            />
          </div>

          <div>
            <h2 i18n class="text-sm font-light text-gray-600">Sample ID</h2>

            <p class="mb-5 mt-1 text-xl text-black">{{ sample.id }}</p>
          </div>

          <div>
            <h2 i18n class="text-sm font-light text-gray-600">
              Sample location
            </h2>

            <p class="mb-5 mt-1 text-xl text-black">
              {{ sample.sampleDescription }}
            </p>
          </div>

          <div>
            <h2 i18n class="text-sm font-light text-gray-600">Sample type</h2>

            <p class="mb-5 mt-1 text-xl text-black">{{ sample.type }}</p>
          </div>

          <div>
            <h2 i18n class="text-sm font-light text-gray-600">Sample status</h2>

            <p class="mb-5 mt-1 text-xl text-black">{{ sample.status }}</p>
          </div>

          <div *ngIf="sample.timestamp" class="flex">
            <div
              class="flex items-center rounded-full bg-alpha-green px-3 py-2"
            >
              <app-icon icon="clock" height="20px" fill="black" class="mr-2" />
              <p i18n class="text-sm">
                {{ sample.timestamp * 1000 | date:'HH:mm' }}
              </p>
            </div>
          </div>

          <app-menu
            [show]="sampleMenuOpen === sample.id"
            (closed)="sampleMenuOpen = ''"
          >
            <app-menu-action
              i18n-text
              text="View sample details"
              (click)="viewSample(sample.orderId + '-' + sample.id)"
            />
          </app-menu>
        </div>

        <div
          *ngIf="(isSamplesPending$ | async) === false"
          class="flex min-h-40 items-center justify-center"
        >
          <app-button
            icon="add"
            iconFill="blue"
            iconHeight="28px"
            (click)="showCreateSampleModal = true"
            classes="w-20 h-20 flex justify-center rounded-full items-center shadow"
          />
        </div>
      </ng-container>
    </div>

    <ng-template #samplesLoadingSpinner>
      <div class="mt-20 flex items-center justify-center">
        <app-loading />
      </div>
    </ng-template>
  </ng-template>
</div>

<ng-template #showLoadingSpinner>
  <div class="mt-20 flex items-center justify-center">
    <app-loading />
  </div>
</ng-template>

<app-create-sample-modal
  [show]="showCreateSampleModal"
  [jobId]="jobId"
  (closed)="showCreateSampleModal = false"
/>

<app-delete-job-modal
  [show]="showDeleteJobModal"
  [jobId]="jobId"
  (closed)="deleteJobModalClosed()"
  (cancelled)="showDeleteJobModal = false"
/>
