<app-modal-layout (clickedOutside)="cancel()" [hidden]="!show">
  <div
    class="h-full w-full overflow-auto rounded-none bg-white p-14 md:h-auto md:w-auto md:rounded-2xl"
  >
    <ng-container *ngIf="view === 'initial'; else responseView">
      <div class="mt-6">
        <h2 i18n class="mb-2 text-2xl font-medium">Create a sample</h2>
      </div>

      <form
        class="mt-12 grid grid-cols-1 gap-x-2 md:grid-cols-2 md:gap-x-4"
        [formGroup]="form"
        (submit)="createSample($event)"
      >
        <div>
          <app-input
            [form]="form"
            controlName="sampleDescription"
            i18n-label
            label="Sample location/description"
            i18n-placeholder
            placeholder="Sample description"
            name="sampleDescription"
          />

          <div
            *ngIf="sampleTypes$ | async; let sampleTypes"
            class="mb-4 flex flex-col"
          >
            <h2 i18n class="mb-2 text-sm font-light text-gray-600">
              Sample type
            </h2>
            <ng-select
              [items]="sampleTypes"
              formControlName="type"
              [searchable]="false"
              [clearable]="false"
              i18n-placeholder
              placeholder="Please select"
            >
            </ng-select>
          </div>

          <div class="mb-4 flex flex-col">
            <h2 i18n class="mb-2 text-sm font-light text-gray-600">
              Matrix type
            </h2>
            <ng-select
              [items]="matrixTypes"
              formControlName="matrix"
              bindLabel="name"
              bindValue="id"
              [searchable]="false"
              [clearable]="false"
              i18n-placeholder
              placeholder="Please select"
            >
            </ng-select>
          </div>
        </div>

        <div>
          <div class="mb-4 flex flex-col">
            <h2 i18n class="mb-2 text-sm font-light text-gray-600">
              Test suite required
            </h2>
            <ng-select
              [items]="testSuites"
              formControlName="suiteCode"
              [searchable]="false"
              [clearable]="false"
              bindLabel="name"
              bindValue="id"
              i18n-placeholder
              placeholder="Please select"
            >
            </ng-select>
          </div>

          <div class="mb-4">
            <h2 i18n class="mb-2 text-sm font-light text-gray-600">Notes</h2>

            <textarea
              class="h-60 w-full resize-none rounded border border-alpha-gray-80 p-4"
              formControlName="notes"
              placeholder="Start typing your notes here..."
              i18n-placeholder
              name="notes"
              id="notes"
            ></textarea>
          </div>
        </div>

        <div class="mt-8 flex flex-col md:flex-row md:justify-start">
          <app-button
            classes="w-full md:w-auto px-4"
            type="button"
            [disabled]="loading"
            i18n-text
            (click)="close()"
            text="Cancel"
          />

          <app-button
            type="submit"
            [disabled]="loading || form.invalid"
            i18n-text
            icon="add"
            iconFill="white"
            iconHeight="14px"
            text="Create sample"
            classes="w-full md:w-auto px-4 md:ml-4 mt-4 md:mt-0"
          />
        </div>
      </form>
    </ng-container>

    <ng-template #responseView>
      <div class="flex h-full w-full flex-col items-center justify-center">
        <ng-container *ngIf="loading">
          <app-loading></app-loading>
        </ng-container>

        <ng-container *ngIf="!loading">
          <ng-container *ngIf="error; else responseSuccess">
            <div
              class="mx-auto flex h-40 w-40 items-center justify-center rounded-full bg-alpha-green"
            >
              <div class="h-16">
                <app-icon icon="warning" />
              </div>
            </div>

            <div class="mt-6 text-center">
              <h2 i18n class="mb-2 text-2xl font-medium">An error occurred</h2>
              <p i18n class="font-light">
                Unable to create sample, please try again.
              </p>
            </div>

            <div class="mt-8 flex w-full flex-col">
              <app-button
                type="submit"
                i18n-text
                text="Try again"
                classes="w-full"
              />
              <app-button
                i18n-text
                text="Cancel"
                (click)="close()"
                classes="w-full mt-4"
              />
            </div>
          </ng-container>

          <ng-template #responseSuccess>
            <div
              class="mx-auto flex h-40 w-40 items-center justify-center rounded-full bg-alpha-green"
            >
              <div class="h-16">
                <app-icon icon="success" />
              </div>
            </div>

            <div class="mt-6 text-center">
              <h2 i18n class="mb-2 text-2xl font-medium">Sample created!</h2>
              <p i18n class="font-light">Sample created successfully.</p>
            </div>

            <div class="mt-8 flex flex-col">
              <button (click)="close()" i18n class="text-alpha-blue">
                Back to dashboard
              </button>
            </div>
          </ng-template>
        </ng-container>
      </div>
    </ng-template>
  </div>
</app-modal-layout>
