<app-modal-layout (clickedOutside)="cancelled.emit()" *ngIf="show">
  <div class="rounded-2xl bg-white p-14">
    <ng-container *ngIf="view === 'initial'; else responseView">
      <div
        class="mx-auto flex h-40 w-40 items-center justify-center rounded-full bg-alpha-green"
      >
        <div class="h-16">
          <app-icon icon="delete" />
        </div>
      </div>

      <div class="mt-6 text-center">
        <h2 i18n class="mb-4 text-3xl font-medium">Delete job?</h2>
        <p i18n class="mb-8">
          Are you sure you would like to delete <br />
          job {{ jobId }}?
        </p>
      </div>

      <div class="mt-8 flex flex-col">
        <app-button
          type="submit"
          (click)="confirm()"
          i18n-text
          text="Yes, delete this job"
          classes="w-full"
        />
        <app-button
          i18n-text
          text="Cancel"
          (click)="cancelled.emit()"
          classes="w-full mt-4"
        />
      </div>
    </ng-container>

    <ng-template #responseView>
      <div class="flex h-full w-full flex-col items-center justify-center">
        <ng-container *ngIf="loading">
          <app-loading></app-loading>
        </ng-container>

        <ng-container *ngIf="!loading">
          <ng-container *ngIf="error; else responseSuccess">
            <div
              class="mx-auto flex h-40 w-40 items-center justify-center rounded-full bg-alpha-green"
            >
              <div class="h-16">
                <app-icon icon="warning" />
              </div>
            </div>

            <div class="mt-6 text-center">
              <h2 i18n class="mb-2 text-2xl font-medium">An error occurred</h2>
              <p i18n class="font-light">
                Unable to create sample '{{ jobId }}', please try again.
              </p>
            </div>

            <div class="mt-8 flex w-full flex-col">
              <app-button
                type="submit"
                i18n-text
                text="Try again"
                classes="w-full"
              />
              <app-button
                i18n-text
                text="Cancel"
                (click)="cancelled.emit()"
                classes="w-full mt-4"
              />
            </div>
          </ng-container>

          <ng-template #responseSuccess>
            <div
              class="mx-auto flex h-40 w-40 items-center justify-center rounded-full bg-alpha-green"
            >
              <div class="h-16">
                <app-icon icon="success" />
              </div>
            </div>

            <div class="mt-6 text-center">
              <h2 i18n class="mb-4 text-3xl font-medium">Job deleted!</h2>
              <p i18n class="mb-8">
                Job {{ jobId }} has been successfully deleted.
              </p>
            </div>

            <div class="mt-8 flex flex-col">
              <button (click)="close()" i18n class="text-alpha-blue">
                Back to dashboard
              </button>
            </div>
          </ng-template>
        </ng-container>
      </div>
    </ng-template>
  </div>
</app-modal-layout>
